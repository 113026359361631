<template>
  <div :class="PanelClass" v-show="isActive">

    <div class="payitemavailable " > <!-- v-show="priorValid" -->
    
      <addpayment buttonClass='btn cashpaymentbutton' name="Cash" :defaultAmount="due" @save="addPayItem" @opendrawer="OpenDrawer" 
            v-if="showCash" :disabled="disablePayments"/>
      <addpayment buttonClass='btn paymentbutton' name="Check" :defaultAmount="due" @save="addPayItem" :isCheck="paramTrue" :numberDataLabel="checkNumberDataLabel" 
            v-if="showCheck" :disabled="disablePayments"/>
      
      <addpayment buttonClass='btn paymentbutton' name="Card" :defaultAmount="due" @save="addPayItem" :numberDataLabel="cardNumberDataLabel" 
            v-if="showBankcard" :disabled="disablePayments"/>
       <addpayment buttonClass='btn paymentbutton' :name="accountBalanceLabel" :defaultAmount="due" @save="addPayItem" 
            v-if="showAccount" :disabled="disablePayments"/>
       <addpayment_ar buttonClass='btn paymentbutton' :name="arLabel" :defaultAmount="due" @save="addPayItem_ar"   
            v-if="showAR"  :subtypeList="arPaymentItemSubtypes" :disabled="disablePayments"/>
       <addpayment buttonClass='btn paymentbutton' :name="faBalanceLabel" :defaultAmount="fadefaultpay" @save="addPayItem" 
          v-if="showFA" :allowOverAvailableAmount="paramFalse" :availableAmount="faBalance" :disabled="disableFA || disablePayments"/>
       <addpayment buttonClass='btn paymentbutton' name="Gift Certificate" :defaultAmount="due" @save="addPayItem" 
          v-if="showGiftCertificate" :numberDataLabel="certNumberDataLabel" :disabled="disablePayments"/>
       <addpayment_gift buttonClass="btn paymentbutton" name="Gift Card" :defaultAmount="due" @save="addPayItem" 
          v-if="showGiftcard" :disabled="disablePayments"/>
      
      <!-- <addpayment buttonClass='btn paymentbutton' name="Other" :defaultAmount="due" @save="addPayItem"/> -->
      <div class="allpmt">
        <button class='btn paymentbutton' @click="addPayItemAccountAll" v-if="showAccountShortcut" :disabled="disablePayments">{{accountAllLabel}}</button>
      </div>
    </div>
    <div class="payitemadded ">
      <label class="eligiblemessage" v-if="cartEligibleForFA">*All items in Cart Eligible For Financial Aid*</label>
      <label for class="text subtitle">Payments</label>
      <payitem v-for="payItem in payItems" v-bind:key="payItem.id" v-bind:payItem="payItem"  @removeitem="removeitem" />
      <label for class="text text-right totaltext">Payment Total: {{ payTotal|toCurrency }}</label>
      <label for class="text text-right totaltext paycolor">Due: {{ due|toCurrency }}</label>
      <label for class="text text-right totaltext paycolor">Change: {{ change|toCurrency }}</label>
      <div v-show="isValid && zeroDue">
        <!-- <button class="btn actionbutton narrowbutton"  @click="getReceiptData" v-if="receiptPrinterType=='ip'" >Receipt</button> -->
        <button class="btn actionbutton narrowbutton"  @click="receiptOnNewPage" v-if="receiptPrinterType=='newpage'" >Print Receipt</button>
        <button ref="printthereceipt" class="btn actionbutton narrowbutton"  v-print="'#receiptdatadiv'" v-if="receiptPrinterType=='print'">Print Receipt</button>
        <!-- <button ref="drawer" class="btn actionbutton narrowbutton"  v-print="'#drawerdiv'" >drawer</button> -->
        <!-- <div  class="receiptclass" id="receiptdatadiv" v-if="receiptData!=null && receiptIP=='laser'" >
          <label>{{receiptData}}</label>
        </div> -->
      </div>
      
      

    </div>


    <div class="nextbutton">
      <div class="nextbutton">
        <label class="pleasewait" v-if="inPayment">Processing, please wait...</label>
      </div>

      <div v-if="showPayMultCard">
        <button v-for="payItem in payItems_unprocessedCardOnly" v-bind:key="payItem.id"   class="btn actionbutton" 
            @click="process_terminaltest_single(payItem)" >Process Card for ${{payItem.price}}</button>
      </div>

      <button class="btn actionbutton" @click="process_terminaltest" v-show="isValid && zeroDue && !showPayMultCard">{{completeText}}</button>
        <!-- <a href="file:///c:/senddatv120/test.bat">test</a> -->
        <!-- <button class="btn actionbutton"  @click="OpenDrawer">Open Drawer</button> -->
        <div  class="receiptclass" id="receiptdatadiv" v-if="receiptData!=null" >
          <label>{{receiptData}}</label>
        </div>
        <div id="drawerdiv">
          <label class="drawerfont"></label>
        </div>
    </div>
 
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import payitem from '../actions/payitem.vue'
import format from '../../style/format.js'
import datamethods from '../../services/datamethods.js'
import addpayment from '../actions/addpayment.vue'
import addpayment_gift from '../actions/addpayment_gift.vue'
import addpayment_ar  from '../actions/addpayment_ar.vue'
import print from 'vue-print-nb'
//import qz from 'qz-tray'
export default {
  data() {
    return {
      paramTrue:true,
      paramFalse:false,

      terminalInfo:{},
      terminalResponse:null,

      receiptData:null,


      print2default: false,
      printers: [],
      selected_printer: '',
      printmanager:null,
      haveUnprocessedCard:false
    };
  },
  directives:{
    print
  },
  components:{
    payitem,
    addpayment,
    addpayment_gift,
    addpayment_ar

  },
  mixins:[format,datamethods],
  props: ["panelIndex"],
  computed: {
    ...mapFields([
            'panels',
            'inPayment',
            'payItems',
            'payTotal',
            'cartTotal',
            'due',
            'change',
            'isCash',
            'guest',
            'terminalId',
            'ticket',
            'guestAccounts',
            'responseMessage',
            'receiptIP',
            'cartEligibleForFA',
            'faBalance',
            'arPaymentItemSubtypes',
            'isCompletable'
        ]),
        
        disablePayments(){
          var single = false
          if(window.SINGLE_PAYMENT!=undefined){single=window.SINGLE_PAYMENT}
          return single && this.payItems.length>0
        },
        disableFA(){
          return !this.cartEligibleForFA  || this.faBalance<=0
        },
        fadefaultpay(){
          if(this.faBalance>this.due){return this.due}
          else{return this.faBalance}
        },
    isMultipleCard(){
      let temp = this.payItems;
      if(temp!=undefined){
          temp = temp.filter((item)=>{
              return (item.name.toLowerCase()=='card')
          })
      }        
      return temp.length>1
    },
    payItems_unprocessedCardOnly(){
      
      let temp = this.payItems;
      if(temp!=undefined){
          temp = temp.filter((item)=>{
              return (item.name.toLowerCase()=='card' && item.authorizationCode==null)
          })
      }        
      return temp
             
    },
    unprocessedCardCount(){
      return this.payItems_unprocessedCardOnly.length>0
    },
    showPayMultCard(){
      return this.isMultipleCard && this.unprocessedCardCount>0
    },
    isActive: function() {
      return this.panels[this.panelIndex].active;
    },
    isValid: function() {
      return this.panels[this.panelIndex].valid;
    },
    zeroDue:function(){
      return this.due==0
    },
    priorValid: function() {
      return this.panels[this.panelIndex - 1].valid;
    },
    payButtonText: function() {
      if (this.inPayment) {
        return "Processing Payment. Please wait...";
      } else {
        return "Process Payment";
      }
    },
    completeText(){
      if(this.haveUnprocessedCard){
        return "Process Card and Complete Transaction"

      }else{
        return "Complete Transaction"
      }
    },
    PanelClass: function() {
      var classes = "panel-full panel payfull ";
      if (this.inPayment == true) {
        classes += " panel-unavailable";
      }
      return classes;
    },
    accountBalance(){
      if(this.guestAccounts==undefined){ return -1; }
      if(this.guestAccounts.length==0){return -1}
      return this.guestAccounts[0].currentBalance

    },
    
    accountBalanceLabel(){
      var acctlabel="Account"
      if(window.ACCOUNT_LABEL!=undefined){acctlabel=window.ACCOUNT_LABEL}
      var bal = this.accountBalance;
      if(bal==-1){
        return "No Account Available"
      }else{
        return acctlabel + ": " + this.$options.filters.toCurrency(bal)
      }
      
    },
    arLabel(){
      var lbl = "AR"
      if(this.ARPaymentItemSubtypeName!=null){
        lbl = this.ARPaymentItemSubtypeName
      }
      return lbl
    },
    faBalanceLabel(){
      var acctlabel="Financial Aid"
      var bal = this.faBalance;
      if(bal==-1){
        return "No Financial Aid Available"
      }else{
        return acctlabel + ": " + this.$options.filters.toCurrency(bal)
      }
    },
    accountAllLabel(){
      var acctlabel="Account"
      if(window.ACCOUNT_LABEL!=undefined){acctlabel=window.ACCOUNT_LABEL}
      return "Pay All Now with " + acctlabel 
    },
    AutoStartOver(){
      if(window.AUTO_START_OVER==undefined){return false}
      else{return window.AUTO_START_OVER}
    },
    integratedCards(){
      
      if(window.INTEGRATED_CARDS==undefined){return false}
      else{return window.INTEGRATED_CARDS}
    },
    showCheck(){
      
      if(window.SHOW_CHECK==undefined){return false}
      else{return window.SHOW_CHECK}
    },
    showAR(){
      
      if(window.SHOW_AR==undefined){return false}
      else{return window.SHOW_AR}
    },
    showCash(){
      if(window.SHOW_CASH==undefined){return false}
      else{return window.SHOW_CASH}
    },
    showBankcard(){
      if(window.SHOW_BANKCARD==undefined){return false}
      else{return window.SHOW_BANKCARD}
    },
    showGiftcard(){
      if(window.SHOW_GIFTCARD==undefined){return false}
      else{return window.SHOW_GIFTCARD}
    },
    showGiftCertificate(){
      if(window.SHOW_GIFTCERTIFICATE==undefined){return false}
      else{return window.SHOW_GIFTCERTIFICATE}
    },
    showAccount(){
      if(window.SHOW_ACCOUNT==undefined){return false}
      else{return window.SHOW_ACCOUNT}
    },
    showFA(){
      if(window.SHOW_FINANCIALAID==undefined){return false}
      else{return window.SHOW_FINANCIALAID}
    },
    cardNumberDataLabel(){
      if(this.integratedCards){ return ""}
      else {return "Last 4 Digits"}
    },
    checkNumberDataLabel(){

      return "Check #"
    },
    certNumberDataLabel(){
      return 'Certificate #'
    },
    showAccountShortcut(){
      if(window.SHOW_ACCOUNT_SHORTCUT==undefined){return false}
      else return window.SHOW_ACCOUNT_SHORTCUT
    },
    receiptPort(){
      if(window.RECEIPT_PORT==undefined){return false}
      else return window.RECEIPT_PORT
    },
    receiptPrinterType(){
      if(this.receiptIP=='laserapple' || this.receiptIP=='newpage'){return 'newpage'}
      else{return 'print'}
    },
    
    
  },
  methods: {
    autoPrintReceiptOnSave(name){
          var autoprint=true
          if(window.AUTOPRINT_RECEIPT_FOR_CASH!=undefined)(autoprint=window.AUTOPRINT_RECEIPT_FOR_CASH)
          return name=='Cash' && autoprint
      },
    addPayItem(type,amt,num,change){
      var autoreceipt = this.autoPrintReceiptOnSave(type)
      var newItem;
      //add anew one   
        newItem = {
          id:1,
          name:type,
          price:amt,
          numberData:num,
          changeGiven:change,
          authorizationCode:null
          
        }
        /* if(type=='AR'){
          newItem.subTypeId=this.ARPaymentItemSubtypeId
        } */
        this.addItemToData(newItem,autoreceipt)
        
    },
    addPayItem_ar(type, amt, subtypeid){
      var autoreceipt = this.autoPrintReceiptOnSave(type)
      var newItem;
      //add anew one   
        newItem = {
          id:1,
          name:type,
          price:amt,
          authorizationCode:null,
          subTypeId:subtypeid
        }

       
        this.addItemToData(newItem,autoreceipt)
    },
    addPayItemAccountAll(){
      this.addPayItem(this.accountBalanceLabel, this.due, null)
    },
    removeitem(ItemID){
      var i;
        for (i = 0; i <= this.payItems.length - 1; i++) {
          if (this.payItems[i].id == ItemID) {
              this.removeItemFromData(this.payItems[i])
              this.payItems.splice(i,1)
              break; //only take this one
          }
        }
        this.updateTotals();
    },
    updateTotals(autoreceipt=false){
      this.responseMessage=null
      var i;
      var total = 0;
      this.haveUnprocessedCard=false;

        for (i = 0; i <= this.payItems.length - 1; i++) {
          console.log('total ' + total)
          console.log('price ' + this.payItems[i].price + ' ' + Number(this.payItems[i].price))
          total = total + Number(this.payItems[i].price);
          console.log('total ' + total)
          //console.log(this.payItems[i].name + ' ' + this.payItems[i].authorizationCode)
          if(this.payItems[i].name=='Cash'){
            this.isCash=true
          }
          if(this.payItems[i].name.toLowerCase()=='card' && this.payItems[i].authorizationCode==null){
            this.haveUnprocessedCard=true
            console.log(this.payItems[i].name + ' ' + this.payItems[i].authorizationCode)
            console.log(this.haveUnprocessedCard)
          }
        }
        console.log('add up pmts ' + this.total)
        this.payTotal = total.toFixed(2);
        this.due = (this.ticket.total-this.payTotal).toFixed(2);

        //account for change
        //console.log('b4 change: ' + this.due +  ' ' + this.change + ' ' +  this.isCash)
        if(this.due<0 && this.isCash==true){
          this.change=this.due * -1;
          this.due=0
        }
        //console.log('after change: ' + this.due +  ' ' + this.change)

        var amt = this.$options.filters.toCurrency(this.ticket.total);
        this.panels[2].title = 'Payment: ' + amt ;
        if(this.due==0){
          this.panels[2].valid = true;
          this.panels[3].available = true;

          //go ahead and get receipt data if we need it
          if(this.receiptIP!='ip'){
            this.getReceiptData(autoreceipt)
          }
        }else{
          this.panels[2].valid = false;
          this.panels[3].available = false;
        }
        this.$emit('refreshticket')
    },
    async process_terminaltest(){
      //if we aren't integrated, then skip to the after
      if(this.integratedCards==false){
        this.process()
      }else{
        let req = {
          ticket:this.ticket
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'terminal')

        //handle response
        if(response.Successful){
          this.terminalInfo=response

          //if no payment then move on
          if(this.terminalInfo.payment==null){
            console.log('no pmt')
            this.process(); //do the final processing
            return;
          }
          else if (this.terminalInfo.payment.transactionAmount==null){
            //console.log('pmt amt 0')
            this.process(); //do the final processing
            return;
          }

          //call 
          let req2=this.terminalInfo.payment
          let headers = this.terminalInfo.headers
          //headers
          for(let i=0;i < headers.length;i++){
            this.AddHeaderToTerminalAPI(headers[i].key, headers[i].value)
          }
          
          if(this.terminalInfo.uri.includes('refund')){
            var response2 = await this.callAPI_Terminal(req2,'refund')
            this.pleaseWait=false;
            this.completeTerminal(response2)
          }else{
            var response2 = await this.callAPI_Terminal(req2,'sale')
            this.pleaseWait=false;
            this.completeTerminal(response2)
          }

          
         
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
      }

       
    },
    async completeTerminal(response){
      let req = {
          ticket:this.ticket,
          response:response
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'terminalcomplete')

        //handle response
        if(response.Successful){
          
          this.process() //finish!
        }else{
          this.responseMessage = response.Message
          this.$emit('refreshticketrebuild',this.responseMessage)
          
        }
    },

    //single item only!!
    async process_terminaltest_single(payItem){
      var item = {
        id:payItem.id,
        amount:payItem.price
      }


      //if we aren't integrated, then skip to the after
      if(this.integratedCards==false){
        this.process()
      }else{
        let req = {
          ticketPaymentItem:item
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'terminal_byitem')

        //handle response
        if(response.Successful){
          this.terminalInfo=response

          //if no payment then move on
          if(this.terminalInfo.payment==null){
            //console.log('no pmt')
            return
          }
          else if (this.terminalInfo.payment.transactionAmount==null){
            //console.log('pmt amt 0')
            return
          }

          //call 
          let req2=this.terminalInfo.payment
          let headers = this.terminalInfo.headers
          //headers
          for(let i=0;i < headers.length;i++){
            this.AddHeaderToTerminalAPI(headers[i].key, headers[i].value)
          }
          
          var response2 = await this.callAPI_Terminal(req2,'sale')
          console.log(response2)
          this.completeTerminal_single(response2,item)
         
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
      }

       
    },
    async completeTerminal_single(response,item){
      let req = {
          ticket:this.ticket,
          ticketPaymentItem:item,
          response:response
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'terminalcomplete_byitem')

        //handle response
        if(response.Successful){
          //instead of finishing ...we just refresh!
          this.$emit('refreshticketrebuild',this.responseMessage)
        }else{
          this.responseMessage = response.Message
          this.$emit('refreshticketrebuild',this.responseMessage)
          
        }
    },


    async process(){
      this.inPayment=true
      let req = {
          ticket:this.ticket
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'processticketpayment')
        //handle response
     
        if(response.Successful){
           this.inPayment=false
           this.toNext()
        }else{
          this.responseMessage = response.Message
          this.inPayment=false
          this.pleaseWait=false;
        }
        this.pleaseWait=false;
    },
    toNext: function() {
      if(this.AutoStartOver){this.startOver();}
      else{
        var nextPanelIndex = Number(this.panelIndex) + 1;
        this.$store.dispatch('setActivePanel',nextPanelIndex);
        this.panels[2].available = false;
        this.panels[1].available = false;
        this.panels[0].available = false;
      }
      
    },
    startOver: function() {
      this.$store.dispatch('startOver');
      this.$emit('startover')
    },
    async addItemToData(item, autoreceipt=false){
      let req = {
          ticketPaymentItem:{
            ticketPaymentId:this.ticket.ticketPaymentId,
            ticketId:this.ticket.id,
            typeName:item.name,
            terminalId:this.terminalId,
            businessDayId:this.ticket.businessDayId,
            guestId:this.guest.id,
            amount:item.price,
            numberData:item.numberData,
            guestAccountBalance:this.accountBalance,
            changeGiven:item.changeGiven,
            subTypeId:item.subTypeId

          },
          locationId:this.ticket.locationId
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'addticketpaymentitem')
        //handle response
     
        if(response.Successful){
           item.id=response.ResponseInteger
           this.payItems.push(item);
            this.updateTotals(autoreceipt)
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },
    async removeItemFromData(item){
      let req = {
          ticketPaymentItem:{
            id:item.id,
            ticketId:this.ticket.id
          }
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'removeticketpaymentitem')
        //handle response
     
        if(response.Successful){
       
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },

    //receipt
    OpenDrawer(){
       
        

      },
      receipt(drawer){
     
        var receiptText
        if(drawer){
          receiptText="drawer"
        }else{
          receiptText= this.receiptData
        }
        //test receipt
        var ePosDev = new epson.ePOSDevice();
        var printer = null
        var ipAddress = this.receiptIP;

        //if we don't have an ip...say so
        if(ipAddress==''){
          console.log("no receipt printer IP available. Please check URL and configuration")
          return
        }

        var port = this.receiptPort;
        ePosDev.connect(ipAddress, port, callback_connect);

        function callback_connect(data) {
          if(data == 'OK') {
            ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER, {'crypto' : true, 'buffer' : false}, cbCreateDevice_printer);
          } else {
            console.log(data);
          }
        }
        function cbCreateDevice_printer(devobj, retcode) {
          if( retcode == 'OK' ) {
            printer = devobj;
            console.log(this.printer)
            executeAddedCode();
          } else {
            console.log(retcode);
          }
        }
        function executeAddedCode() {
          console.log('thinking about printing')
          if(receiptText=="drawer"){
            console.log('drawer please')
            printer.addSound(printer.PATTERN_A, 1, 1000);
          }else{
            console.log('receipt please')
            printer.addText(receiptText)
            printer.addCut(printer.CUT_FEED);
          }
          printer.send();
        }
      },
      async getReceiptData(autoreceipt=false){
        let req = {
            ticket:{
              id:this.ticket.id
            }
          }  
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'ticketreceipt')
            //handle response
        
            if(response.Successful){
              this.receiptData=response.StringResponse
              this.pleaseWait=false;
              //this.receipt() //print!
              
              if(autoreceipt){this.$refs.printthereceipt.click()}
              
            }else{
              this.responseMessage = response.Message
            }
            this.pleaseWait=false;
      },
      laser(){

      },
      receiptOnNewPage(){
      console.log('print receipt new page')
        var mywindow = window.open('', 'PRINT', 'height=650,width=350');

        mywindow.document.write('<html><head><title>' + 'receipt'  + '</title>');
        mywindow.document.write('<style>body{white-space:pre-wrap;}</style>');
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById('receiptdatadiv').innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
      },
      /* jsprintme(){
        let cpj = new JSPM.ClientPrintJob();
        
        cpj.clientPrinter = new JSPM.DefaultPrinter();
        var cmds = this.receiptData 
        
        cpj.printerCommands = cmds;
        cpj.sendToClient();
      },
      onPrinterChange: function(value) {
      this.selected_printer = value;
      console.info("Selected printer:", value);
    },
      onInit: function() {
      var _this = this;
      this.printmanager=JSPM.JSPrintManager
      JSPM.JSPrintManager.auto_reconnect = true;
      JSPM.JSPrintManager.start();
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        _this.getPrinters().then((p)=>{
          _this.printers = p;
          _this.$nextTick();
        });
      };
    },
    getPrinters: function() {   
      return new Promise((ok, err) => {
        let printers = [];
        if(JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters().then(function (myPrinters) {
            printers = myPrinters;
            console.log(printers);
            ok(printers);
          }).catch((e)=>err(e));
        } else { console.warn("JSPM WS not open"); ok(printers); }
      });       
    } */

  },
  mounted: function() {
    //this.onInit();
  }
};
</script>
<style scoped>
.drawerfont{
  font:'control';
}
.payitemavailable {
  grid-area: payavailable;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height:100px;
}
.payitemadded {
  grid-area: payadded;
  display: flex;
  flex-direction: column;
  border-left: 1px solid gray;
  padding-left:20px;
  margin-left:10px;
  text-align:right;
  align-items: right;
}

.payfull {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 80px;
  grid-template-areas:
    "payavailable payadded"
    "open next";
  padding: 20px;
}
.allpmt{
  border-top:1px solid gray;
  width:100%;
  text-align: left;
}
.narrowbutton{
  width:100px;
}
.receiptclass{
  margin-top:120px;
  white-space: pre-wrap;
} 
</style>